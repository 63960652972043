/* Pretty Privacty Policy CSS */

.privacy-policy {
  max-width: 800px;
  padding: 20px;
}

.privacy-policy .lst-kix_kis5it4k6k4w-5 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_kis5it4k6k4w-6 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_kis5it4k6k4w-3 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_kis5it4k6k4w-4 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_kis5it4k6k4w-7 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_kis5it4k6k4w-8 > li:before {
  content: "\0025a0   ";
}
.privacy-policy ul.lst-kix_pn5ap2bpxbz2-0 {
  list-style-type: none;
}
.privacy-policy .lst-kix_kis5it4k6k4w-2 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_kis5it4k6k4w-1 > li:before {
  content: "\0025cb   ";
}
.privacy-policy ul.lst-kix_pn5ap2bpxbz2-4 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pn5ap2bpxbz2-3 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pn5ap2bpxbz2-2 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pn5ap2bpxbz2-1 {
  list-style-type: none;
}
.privacy-policy .lst-kix_xp97frou5zjd-2 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_xp97frou5zjd-3 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_xp97frou5zjd-0 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_xp97frou5zjd-4 > li:before {
  content: "\0025cb   ";
}
.privacy-policy ul.lst-kix_564je552ka3v-2 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_564je552ka3v-3 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_564je552ka3v-0 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_564je552ka3v-1 {
  list-style-type: none;
}
.privacy-policy .lst-kix_xp97frou5zjd-1 > li:before {
  content: "\0025cb   ";
}
.privacy-policy ul.lst-kix_pn5ap2bpxbz2-8 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pn5ap2bpxbz2-7 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pn5ap2bpxbz2-6 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pn5ap2bpxbz2-5 {
  list-style-type: none;
}
.privacy-policy .lst-kix_pn5ap2bpxbz2-8 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_pn5ap2bpxbz2-3 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_g6mj1ccn3726-0 > li:before {
  content: "\0025cf   ";
}
.privacy-policy ul.lst-kix_564je552ka3v-8 {
  list-style-type: none;
}
.privacy-policy .lst-kix_pn5ap2bpxbz2-4 > li:before {
  content: "\0025cb   ";
}
.privacy-policy ul.lst-kix_564je552ka3v-6 {
  list-style-type: none;
}
.privacy-policy .lst-kix_g6mj1ccn3726-2 > li:before {
  content: "\0025a0   ";
}
.privacy-policy ul.lst-kix_kis5it4k6k4w-7 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_564je552ka3v-7 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_kis5it4k6k4w-8 {
  list-style-type: none;
}
.privacy-policy .lst-kix_pn5ap2bpxbz2-5 > li:before {
  content: "\0025a0   ";
}
.privacy-policy ul.lst-kix_564je552ka3v-4 {
  list-style-type: none;
}
.privacy-policy .lst-kix_g6mj1ccn3726-1 > li:before {
  content: "\0025cb   ";
}
.privacy-policy ul.lst-kix_564je552ka3v-5 {
  list-style-type: none;
}
.privacy-policy .lst-kix_pn5ap2bpxbz2-6 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_g6mj1ccn3726-4 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_pn5ap2bpxbz2-7 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_g6mj1ccn3726-3 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_pn5ap2bpxbz2-2 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_pn5ap2bpxbz2-0 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_pn5ap2bpxbz2-1 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_lw1suukcpgdr-0 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_lw1suukcpgdr-6 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_lw1suukcpgdr-7 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_lw1suukcpgdr-8 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_g6mj1ccn3726-8 > li:before {
  content: "\0025a0   ";
}
.privacy-policy ul.lst-kix_xp97frou5zjd-5 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_xp97frou5zjd-4 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_xp97frou5zjd-3 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_xp97frou5zjd-2 {
  list-style-type: none;
}
.privacy-policy .lst-kix_pjimji93qoq7-7 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_g6mj1ccn3726-6 > li:before {
  content: "\0025cf   ";
}
.privacy-policy ul.lst-kix_xp97frou5zjd-8 {
  list-style-type: none;
}
.privacy-policy .lst-kix_g6mj1ccn3726-5 > li:before {
  content: "\0025a0   ";
}
.privacy-policy ul.lst-kix_xp97frou5zjd-7 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_xp97frou5zjd-6 {
  list-style-type: none;
}
.privacy-policy .lst-kix_pjimji93qoq7-4 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_pjimji93qoq7-8 > li:before {
  content: "\0025a0   ";
}
.privacy-policy ul.lst-kix_kis5it4k6k4w-3 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_kis5it4k6k4w-4 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_kis5it4k6k4w-5 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_kis5it4k6k4w-6 {
  list-style-type: none;
}
.privacy-policy .lst-kix_pjimji93qoq7-3 > li:before {
  content: "\0025cf   ";
}
.privacy-policy ul.lst-kix_xp97frou5zjd-1 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_xp97frou5zjd-0 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_kis5it4k6k4w-0 {
  list-style-type: none;
}
.privacy-policy .lst-kix_g6mj1ccn3726-7 > li:before {
  content: "\0025cb   ";
}
.privacy-policy ul.lst-kix_kis5it4k6k4w-1 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_kis5it4k6k4w-2 {
  list-style-type: none;
}
.privacy-policy .lst-kix_pjimji93qoq7-2 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_lw1suukcpgdr-5 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_lw1suukcpgdr-4 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_lw1suukcpgdr-2 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_lw1suukcpgdr-1 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_lw1suukcpgdr-3 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_pjimji93qoq7-5 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_pjimji93qoq7-6 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_564je552ka3v-4 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_564je552ka3v-2 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_564je552ka3v-6 > li:before {
  content: "\0025cf   ";
}
.privacy-policy ul.lst-kix_pjimji93qoq7-7 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pjimji93qoq7-6 {
  list-style-type: none;
}
.privacy-policy .lst-kix_564je552ka3v-1 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_564je552ka3v-5 > li:before {
  content: "\0025a0   ";
}
.privacy-policy ul.lst-kix_pjimji93qoq7-8 {
  list-style-type: none;
}
.privacy-policy .lst-kix_564je552ka3v-0 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_564je552ka3v-8 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_564je552ka3v-7 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_pjimji93qoq7-1 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_xp97frou5zjd-8 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_pjimji93qoq7-0 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_xp97frou5zjd-6 > li:before {
  content: "\0025cf   ";
}
.privacy-policy .lst-kix_xp97frou5zjd-7 > li:before {
  content: "\0025cb   ";
}
.privacy-policy .lst-kix_xp97frou5zjd-5 > li:before {
  content: "\0025a0   ";
}
.privacy-policy .lst-kix_564je552ka3v-3 > li:before {
  content: "\0025cf   ";
}
.privacy-policy ul.lst-kix_lw1suukcpgdr-4 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_lw1suukcpgdr-3 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_lw1suukcpgdr-6 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_lw1suukcpgdr-5 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_lw1suukcpgdr-0 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_lw1suukcpgdr-2 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_lw1suukcpgdr-1 {
  list-style-type: none;
}
.privacy-policy li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.privacy-policy ul.lst-kix_g6mj1ccn3726-2 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_g6mj1ccn3726-3 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_g6mj1ccn3726-0 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_g6mj1ccn3726-1 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_g6mj1ccn3726-6 {
  list-style-type: none;
}
.privacy-policy .lst-kix_kis5it4k6k4w-0 > li:before {
  content: "\0025cf   ";
}
.privacy-policy ul.lst-kix_g6mj1ccn3726-7 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_g6mj1ccn3726-4 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_g6mj1ccn3726-5 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pjimji93qoq7-3 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pjimji93qoq7-2 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_g6mj1ccn3726-8 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pjimji93qoq7-5 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pjimji93qoq7-4 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_lw1suukcpgdr-8 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_lw1suukcpgdr-7 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pjimji93qoq7-1 {
  list-style-type: none;
}
.privacy-policy ul.lst-kix_pjimji93qoq7-0 {
  list-style-type: none;
}
.privacy-policy ol {
  margin: 0;
  padding: 0;
}
table td,
.privacy-policy table th {
  padding: 0;
}
.privacy-policy .c2 {
  margin-left: 36pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 24pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy-policy .c6 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy-policy .c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Arial";
  font-style: normal;
}
.privacy-policy .c7 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 24pt;
  font-family: "Arial";
  font-style: normal;
}
.privacy-policy .c4 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy-policy .c5 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 30pt;
  font-family: "Arial";
  font-style: normal;
}
.privacy-policy .c10 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 18pt;
  font-family: "Arial";
  font-style: normal;
}
.privacy-policy .c3 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy-policy .c12 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.privacy-policy .c13 {
  /* background-color: #ffffff; */
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.privacy-policy .c8 {
  color: inherit;
  text-decoration: inherit;
}
.privacy-policy .c0 {
  font-size: 12pt;
  font-weight: 700;
}
.privacy-policy .c9 {
  padding: 0;
  margin: 0;
}
.privacy-policy .c11 {
  font-size: 12pt;
}
.privacy-policy .title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy-policy .subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy-policy li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
.privacy-policy p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
.privacy-policy h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy-policy h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy-policy h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy-policy h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy-policy h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy-policy h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}