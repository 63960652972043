
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dancing+Script:wght@400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.homehero{
  background-color: white;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding-top: 165px;
}

.hero-actions{
  margin-left: 140px;
  margin-top: 20px;
  color: #333e63;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 1.1em;
}

.hero-actions div img{
  width:26px;
  height:26px;
  transform: translateY(7px);
}

.hs{
  margin-bottom: 1px;
}

.hero{
  margin-left: 100px;
}
sub{
  width: 40vw;
  font-weight: bold;
  text-overflow: initial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color: unset;
}

.header-container,.header-container-dark{
  z-index: 1000;
  position: fixed;
  height:60px;
  background-color: white;
  display:flex;
  width:100vw;
  align-items: center;
  width:100vw;
  justify-content: space-between;
}
.hero-image{
  position: absolute;
  right: 8vw;
}

.hero-wrapper{
  height: 100vh;
  display: flex;
  align-items: center;
}

.img1,.img2{
  width: 25vw;
  transform: rotateZ(-20deg);
}

.img2{
  margin-left: -16vw;
}

.header-logo-container img{
  height:50px;
  margin-left: 20px;
  margin-top: 10px;
  border-radius: 8px;
}

.header-menu-container{
  display: flex;
}

.header-menu-item,.header-menu-item-dark{
  margin-left: 40px;
  cursor: pointer;
  color: #333e63;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
}

.header-button-container button{
  margin-right: 30px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  background-color: #ff5745;
  color:#fff;
  padding:9px 26.25px;
  font-weight: bold;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.header-container-dark {
  background-color: #333; /* Dark background for the header */
  color: #fff; /* Light text color */
}

.header-menu-item-dark {
  color: #bbb; /* Lighter text color for menu items */
}

.header-menu-item-dark a {
  color: #bbb; /* Lighter text color for links in menu items */
  text-decoration: none; /* Optional: Removes underline from links */
}

.header-menu-item-dark a:hover {
  color: #fff; /* White text color on hover for better contrast */
}

.header-button-container-dark {
  background-color: #444; /* Slightly lighter dark background for buttons */
  border-radius: 4px; /* Optional: Adds rounded corners to the button container */
}

.header-button-container-dark button {
  background-color: #555; /* Dark background for buttons */
  color: #fff; /* Light text color for buttons */
  border: none; /* Removes the default button border */
  padding: 10px 20px; /* Adds some padding inside the buttons */
  border-radius: 4px; /* Adds rounded corners to the buttons */
  cursor: pointer; /* Changes the mouse cursor to a pointer on hover */
  margin-right: 30px;
}

.header-button-container-dark button:hover {
  background-color: #666; /* Lighter background color on hover for buttons */
}

/* Ensures the menu burger icon is visible in dark mode */
.header-burger-container-dark img {
  filter: invert(1); /* Inverts the colors of the burger icon for better visibility */
}

@keyframes rgbEffect {
  0% {
    border-color: #FF0000; /* Brighter red */
    box-shadow: 0 0 8px #FF0000;
  }
  33% {
    border-color: #00FF00; /* Brighter green */
    box-shadow: 0 0 8px #00FF00;
  }
  66% {
    border-color: #0000FF; /* Brighter blue */
    box-shadow: 0 0 8px #0000FF;
  }
  100% {
    border-color: #FF0000; /* Back to brighter red */
    box-shadow: 0 0 8px #FF0000;
  }
}



.main-container{
  width:100vw;
  /* background-color: #F4F7FB; */
  display: flex;
  justify-content: center;
}

.facility-container{
  
  padding-top: 150px;
  width: 100%;
  padding-bottom: 100px;
  max-width: 1040px;
}

.bwimg {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.facility-header{
  width: 100%;
  color: #333e63;
  font-family: 'Poppins', sans-serif;
  font-size: 2.8em;
  text-align: center;
  font-weight: bold;
}

.company-header{
  width: 100%;
  color: #333e63;
  font-family: 'Poppins', sans-serif;
  font-size: 60px;
  font-weight: bold;
}

.facility-sub-header{
  width: 100%;
  color: #333e63;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  display: flex;
  margin-bottom: 50px;
  justify-content: center;
 
}
.company-sub-header{
  width: 100%;
  color: #333e63;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
 
}
.facility-sub-header div{
  width: 50vw;
  margin-top: 10px;
  text-align: center;
}
.company-sub-header div{
  width: 50vw;
  margin-top: 10px;
}

.facility-video-container{
  display: flex;
  justify-content: center;
  margin-top: -30px;
}

.facility-video-container iframe{
  margin-top: 100px;
  width: 100%;
  max-width: 1040px;
  height:39vw;
}

.item-body{
  margin-top: 40px;
  max-width: 100%;
}

.item-text-container{
  background-color: #FBFBFD;
  width:100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-image-container{
  background-color: white;
  width:100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.item-image-container img{
  width: 60%;
  height: 60%;
  margin: 20px;
}

.item-text-header{
  color: #333e63;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  font-weight: bold;
}

.item-text-description{
  color: #333e63;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  text-align: justify;
  text-justify: inter-word;
  margin-top: -35px;
}

.item-items-header{
  color: #333e63;
  font-family: 'Poppins', sans-serif;
  font-size: 1.3em;
  margin-top: 0px;
  margin-top: -20px;
  font-weight: bold;
  margin-bottom: -40px;
}

.item-items-item{
  color: #333e63;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  margin-bottom: -40px;
  font-size: 1.1em;
}

.between{
  margin-top: 60px;
  margin-bottom: -20px;
  font-size: 40px;
}

.item-items-item img{
  height: 24px;
  width: 24px;
  transform: translateY(5px);
  
}

.company-hero-bg{
  width: 80vw;
  /* position: absolute;
  top: 280px;
  left: 10vw; */
  margin-top: -100px;
}

.company-hero-bg img{
  width: 100%;
}

.header-burger-container,.header-burger-container-dark{
  display: none;
}



.item-text-container div{
  padding: 20px;
  padding-bottom: 30px;
}

.footer-container{
  padding: 10px 0px;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 0.9em;
  margin-top: 10px;
  background-color: #333e63;
  font-family: 'Poppins', sans-serif;
}

.map-container{
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

#map-canvas{
  width:40vw;
  height:40vw;
}

.contact-container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.contact-container div{
  text-align: left;
}

.contact-container div div{
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}

.map-page{
  background-color: rgb(231, 241, 255);
}



.main-hero-container{
  height:100vh;
  width:100vw;
}

.current-object-description{
  position: absolute;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  width: 33vw;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  background-color: #333e63;
  color: white;
  left:200px;
}



.current-object-header{
  position: absolute;
  font-family: 'Poppins', sans-serif;
  color: #333e63;
  font-size: 2em;
  font-weight: bold;
  left:200px;
}

.current-object-image{
  width:24vw;
  height:24vw;
  position:absolute;
  right:18vw;
}

#header1{
  top:100vh;
}

#product1{
  top:150vh;
  
}

#product2{
  top:450vh;
}

#product3{
  top:750vh;
}

#product4{
  top:1050vh;
}

#description1{
  top:200vh;
}

#header2{
  top:400vh;
}

#description2{
  top:500vh;
}

#header3{
  top:700vh;
}

#description3{
  top:800vh;
}

#header4{
  top:1000vh;
}

#description4{
  top:1100vh;
}

.home-rest{
  position: absolute;
  top:1200vh;
  width:100vw;
  left: 0px;
  background-color: white;
  z-index: 100;
  padding-top: 20px;
}

.hero-header{
  width: 40vw;
}

.sub{
  margin-top: 40px;
  width: 40vw;
}


@media only screen and (max-width: 600px) {
  .header-menu-container,.header-button-container,.header-button-container-dark{
    display: none;
  }
  .header-burger-container,.header-burger-container-dark{
    display: block;
  }
  .header-burger-container img,.header-burger-container-dark img{
    width:25px;
    margin-right: 20px;
  }
  .facility-container{
    padding-top: 120px;
  }
  .facility-header{
    line-height: 110%;
  }
  .facility-sub-header div{
    width: 98vw;
    margin-top: 20px;
    text-align: center;
  }



  .item-image-container img{
    width:33%;
  }



  .item-text-container div{
    padding:14px;
    padding-bottom: 24px;
  }

  .item-text-description{
    margin-top: -30px;
  }

  .item-items-header{
    margin-top: -10px;
    margin-bottom: -30px;
  }
  
  .item-items-item{
    margin-bottom: -30px;
  }

  .facility-video-container{
    margin-top: -50px;
  }

  .facility-video-container iframe{
    width: 95vw;
    height: 54vw;
  }

  .between{
    margin-top: 40px;
    margin-bottom: -20px;
    font-size: 40px;
  }
  #map-canvas{
    height:100vw;
    width:100vw;
  }
  .contact-container div div{
    margin-left: 10px;
    margin-right: 10px;
  }

  .company-header{
    text-align: center;
  }
  .company-hero-bg{
    width:100%;

  }
  .company-hero-bg img{
    margin-top: 80px;
  }

  .company-sub-header div{
    width: 90%;
    margin:20px;
  }

  .current-object-description{
    position: absolute;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: bold;
    width: calc(90vw - 40px);
    display: block;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    background-color: #333e63;
    color: white;
    left:5vw;
  }
  
  
  
  .current-object-header{
    position: absolute;
    font-family: 'Poppins', sans-serif;
    color: #333e63;
    font-size: 2em;
    font-weight: bold;
    left: 20px;
  }
  
  .current-object-image{
    width:80vw;
    position:absolute;
    height: unset;
    left:10vw;
  }

  .hero{
    margin-left: 0px;
    width: 100%;
    text-align: center;
  }

  .hero-header{
    font-size: 2.5em;
    width: 90vw;
    margin-left: 5vw;
    margin-top: -85px;
  }

  .sub{
    text-align: left;
    margin-top: -10px;
  }
  .hero-actions{
    display: none;
    margin-left: 20px;
    margin-top: 20px;
    color: #333e63;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1.1em;
  }



  .hero-image{
    position: absolute; 
    bottom: 60px;   
  }
  
  .hero-wrapper{
    width: 100vw;
    display: flex;
    height: auto;
    align-items: unset;
    justify-content: center;
  }
  
  .img1,.img2{
    width: 60vw;
    transform: rotateZ(-20deg);
  }
  
  .img2{
    margin-left: -32vw;
  }


}

/* .img1, .img2, .current-object-image{
  animation: zoom-in-zoom-out 2s ease infinite;
} */


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}



.title-header{
  width: 100%;
  font-family: 'Poppins', sans-serif;
  color: #001a71;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 2.5em;
  font-weight: bold;
}

.caption-style-4{
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  
}

.caption-style-4 li{
  float: left;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

.caption-style-4 li:hover .caption{
  opacity: 1;
}

.caption-style-4 li:hover img{
  opacity: 1;
  transform: scale(1.15,1.15);
  -webkit-transform:scale(1.15,1.15);
  -moz-transform:scale(1.15,1.15);
  -ms-transform:scale(1.15,1.15);
  -o-transform:scale(1.15,1.15);
}


.caption-style-4 img{
  margin: 0px;
  padding: 0px;
  float: left;
  z-index: 0;
  width: 100%;
}


.caption-style-4 .caption{
  cursor: pointer;
  position: absolute;
  opacity: 0;
  -webkit-transition:all 0.45s ease-in-out;
  -moz-transition:all 0.45s ease-in-out;
  -o-transition:all 0.45s ease-in-out;
  -ms-transition:all 0.45s ease-in-out;
  transition:all 0.45s ease-in-out;
}

.caption-style-4 img{
  -webkit-transition:all 0.25s ease-in-out;
  -moz-transition:all 0.25s ease-in-out;
  -o-transition:all 0.25s ease-in-out;
  -ms-transition:all 0.25s ease-in-out;
  transition:all 0.25s ease-in-out;
}
.caption-style-4 .blur{
  background-color: rgba(0,0,0,0.65);
  height: 300px;
  width: 400px;
  z-index: 5;
  position: absolute;
}

.caption-style-4 .caption-text h1{
  text-transform: uppercase;
  font-size: 16px;
}
.caption-style-4 .caption-text{
  z-index: 10;
  color: #fff;
  position: absolute;
  width: 100%;
  text-align: center;
  height: 100%;
  margin-left: 20px;
  text-align: center;
  top:100px;
}

.zoom {
 
  transition: transform .2s; /* Animation */

}

.zoom:hover {
  cursor: pointer;
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}


/** Nav Menu */
ul.nav-menu{
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  width: 490px;
  margin: 60px auto;
}

ul.nav-menu li{
  display: inline;
  margin-right: 10px;
  padding:10px;
  border: 1px solid #ddd;
}

ul.nav-menu li a{
  color: #eee;
  text-decoration: none;
  text-transform: uppercase;
}

ul.nav-menu li a:hover, ul.nav-menu li a.active{
  color: #2c3e50;
} 

/** content **/
.content{
  margin-top: 100px;
  margin-left: 100px;
  width: 700px;
}
.content h1, .content h2{
  font-family: "Source Sans Pro",sans-serif;
  color: #ecf0f1;
  padding: 0px;
  margin: 0px;
  font-weight: normal;
}

.content h1{
  font-weight: 900;
  font-size: 64px;
}

.content h2{
  font-size:26px;
}

.content p{
  color: #ecf0f1;
  font-family: "Lato";
  line-height: 28px;
  font-size: 15px;
  padding-top: 50px;
}

p.credit{
  padding-top: 20px;
  font-size: 12px;
}

p a{
  color: #ecf0f1;
}

/** fork icon**/
.fork{
  position: absolute;
  top:0px;
  left: 0px;
}

.button-upgrade {
  background: linear-gradient(to right, #8850EA, #C01FF0); /* Gradient from violet to purple */
  color: white; /* White text */
  padding: 10px 20px; /* Top and bottom padding 10px, left and right padding 20px */
  font-size: 16px; /* Font size */
  border-radius: 25px; /* Rounded corners */
  border: none; /* No border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Shadow effect */
  cursor: pointer; /* Pointer cursor on hover */
  transition: all 0.2s ease-in-out; /* Smooth transition for hover effects */
  display: inline-block; /* Allows setting margin and proper alignment */
  margin: 8px 0; /* Margin around the button */
  text-transform: uppercase; /* UPPERCASE text */
}

.button-upgrade:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3); /* Slightly larger shadow on hover */
  transform: scale(1.05); /* Slight increase in size on hover */
}

.button-upgrade:active {
  background: linear-gradient(to right, #7648C4, #AF28D6); /* Slightly darker gradient for active state */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Smaller shadow for button press effect */
  transform: scale(0.95); /* Slight decrease in size to simulate button press */
}
