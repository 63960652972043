/* Pretty terms of service CSS */

@import url(https://themes.googleusercontent.com/fonts/css?kit=OPeqXG-QxW3ZD8BtmPikfA);

.terms-of-service {
  max-width: 800px;
  padding: 100px 20px 20px 20px;
  /* padding: 20px; */
}

.terms-of-service ul.lst-kix_s2ilkvf3sync-8 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_s2ilkvf3sync-6 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_s2ilkvf3sync-7 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_s2ilkvf3sync-4 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_s2ilkvf3sync-5 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_s2ilkvf3sync-2 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_s2ilkvf3sync-3 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_s2ilkvf3sync-0 {
  list-style-type: none;
}
.terms-of-service .lst-kix_s2ilkvf3sync-1 > li:before {
  content: "\0025cb   ";
}
.terms-of-service ul.lst-kix_s2ilkvf3sync-1 {
  list-style-type: none;
}
.terms-of-service .lst-kix_c39p78kw673w-6 > li:before {
  content: "\0025cf   ";
}
.terms-of-service .lst-kix_s2ilkvf3sync-0 > li:before {
  content: "\0025a0   ";
}
.terms-of-service ul.lst-kix_7346lfo61xav-3 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_7346lfo61xav-2 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_7346lfo61xav-5 {
  list-style-type: none;
}
.terms-of-service .lst-kix_c39p78kw673w-7 > li:before {
  content: "\0025cb   ";
}
.terms-of-service .lst-kix_s2ilkvf3sync-5 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_s2ilkvf3sync-7 > li:before {
  content: "\0025cb   ";
}
.terms-of-service ul.lst-kix_7346lfo61xav-4 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_7346lfo61xav-1 {
  list-style-type: none;
}
.terms-of-service .lst-kix_c39p78kw673w-8 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_s2ilkvf3sync-2 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_s2ilkvf3sync-6 > li:before {
  content: "\0025cf   ";
}
.terms-of-service ul.lst-kix_7346lfo61xav-0 {
  list-style-type: none;
}
.terms-of-service .lst-kix_s2ilkvf3sync-3 > li:before {
  content: "\0025cf   ";
}
.terms-of-service .lst-kix_s2ilkvf3sync-4 > li:before {
  content: "\0025cb   ";
}
.terms-of-service .lst-kix_c39p78kw673w-0 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_s2ilkvf3sync-8 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_c39p78kw673w-5 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_ga6ziaebqun5-4 > li:before {
  content: "\0025cb   ";
}
.terms-of-service .lst-kix_ga6ziaebqun5-6 > li:before {
  content: "\0025cf   ";
}
.terms-of-service .lst-kix_c39p78kw673w-4 > li:before {
  content: "\0025cb   ";
}
.terms-of-service .lst-kix_ga6ziaebqun5-5 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_c39p78kw673w-1 > li:before {
  content: "\0025cb   ";
}
.terms-of-service .lst-kix_c39p78kw673w-3 > li:before {
  content: "\0025cf   ";
}
.terms-of-service .lst-kix_ga6ziaebqun5-8 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_c39p78kw673w-2 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_ga6ziaebqun5-7 > li:before {
  content: "\0025cb   ";
}
.terms-of-service .lst-kix_7346lfo61xav-4 > li:before {
  content: "\0025cb   ";
}
.terms-of-service .lst-kix_7346lfo61xav-5 > li:before {
  content: "\0025a0   ";
}
.terms-of-service ul.lst-kix_c39p78kw673w-8 {
  list-style-type: none;
}
.terms-of-service .lst-kix_7346lfo61xav-2 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_7346lfo61xav-3 > li:before {
  content: "\0025cf   ";
}
.terms-of-service .lst-kix_7346lfo61xav-6 > li:before {
  content: "\0025cf   ";
}
.terms-of-service .lst-kix_7346lfo61xav-7 > li:before {
  content: "\0025cb   ";
}
.terms-of-service ul.lst-kix_ga6ziaebqun5-1 {
  list-style-type: none;
}
.terms-of-service .lst-kix_7346lfo61xav-0 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_7346lfo61xav-1 > li:before {
  content: "\0025cb   ";
}
.terms-of-service .lst-kix_7346lfo61xav-8 > li:before {
  content: "\0025a0   ";
}
.terms-of-service ul.lst-kix_ga6ziaebqun5-2 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_ga6ziaebqun5-0 {
  list-style-type: none;
}
.terms-of-service .lst-kix_ga6ziaebqun5-2 > li:before {
  content: "\0025a0   ";
}
.terms-of-service .lst-kix_ga6ziaebqun5-3 > li:before {
  content: "\0025cf   ";
}
.terms-of-service .lst-kix_ga6ziaebqun5-1 > li:before {
  content: "\0025cb   ";
}
.terms-of-service .lst-kix_ga6ziaebqun5-0 > li:before {
  content: "\0025a0   ";
}
.terms-of-service ul.lst-kix_7346lfo61xav-7 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_7346lfo61xav-6 {
  list-style-type: none;
}
.terms-of-service li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.terms-of-service ul.lst-kix_7346lfo61xav-8 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_ga6ziaebqun5-7 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_ga6ziaebqun5-8 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_ga6ziaebqun5-5 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_ga6ziaebqun5-6 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_ga6ziaebqun5-3 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_ga6ziaebqun5-4 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_c39p78kw673w-4 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_c39p78kw673w-5 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_c39p78kw673w-6 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_c39p78kw673w-7 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_c39p78kw673w-0 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_c39p78kw673w-1 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_c39p78kw673w-2 {
  list-style-type: none;
}
.terms-of-service ul.lst-kix_c39p78kw673w-3 {
  list-style-type: none;
}
.terms-of-service ol {
  margin: 0;
  padding: 0;
}
table td,
.terms-of-service table th {
  padding: 0;
}
.terms-of-service .c6 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.terms-of-service .c4 {
  margin-left: 36pt;
  padding-top: 11pt;
  padding-left: 0pt;
  padding-bottom: 11pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service .c2 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service .c0 {
  color: #595959;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.terms-of-service .c3 {
  color: #6c7794;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: "Roboto";
  font-style: normal;
}
.terms-of-service .c19 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 19.5pt;
  font-family: "Arial";
  font-style: normal;
}
.terms-of-service .c20 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.terms-of-service .c13 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14.5pt;
  font-family: "Arial";
  font-style: normal;
}
.terms-of-service .c8 {
  color: #595959;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11.5pt;
  font-family: "Arial";
  font-style: normal;
}
.terms-of-service .c5 {
  color: #595959;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: "Arial";
  font-style: normal;
}
.terms-of-service .c14 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: "Arial";
  font-style: normal;
}
.terms-of-service .c27 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.terms-of-service .c29 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service .c31 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500022727272727;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service .c23 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service .c18 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service .c11 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: normal;
}
.terms-of-service .c21 {
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: normal;
}
.terms-of-service .c28 {
  padding: 0;
  margin: 0;
}
.terms-of-service .c30 {
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.terms-of-service .c1 {
  color: inherit;
  text-decoration: inherit;
}
.terms-of-service .c7 {
  color: #3030f1;
  font-size: 10.5pt;
}
.terms-of-service .c17 {
  color: #000000;
  font-size: 11pt;
}
.terms-of-service .c15 {
  font-size: 10.5pt;
  font-weight: 700;
}
.terms-of-service .c12 {
  height: 11pt;
}
.terms-of-service .c25 {
  color: #0a365a;
}
.terms-of-service .c26 {
  margin-left: 15pt;
}
.terms-of-service .c24 {
  color: #3030f1;
}
.terms-of-service .c9 {
  color: #595959;
}
.terms-of-service .c10 {
  font-size: 11.5pt;
}
/* .terms-of-service .c22 {
  background-color: #ffffff;
} */
.terms-of-service .c32 {
  font-weight: 700;
}
.terms-of-service .c16 {
  font-size: 10.5pt;
}
.terms-of-service .title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service .subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
.terms-of-service p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
.terms-of-service h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms-of-service h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
