@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
:root{
    --black:#000;
    --white:#ffffff;
    --lightgrey:#f1f1f1;
    --darkblue:#0d102c;
    --primarycolor:#3d51f2;
    --secondarycolor:#5670ef;
    --lightDark:#080A25;
    --darkText:#8388B4;
 }
 
/* common styles */
section{
    width: 95%;
    max-width: 1200px;
    margin: auto;
    margin-top: 2em;
}

.gridSection{
    display: grid;
    align-items: center;
    gap: 40px;
    margin-top: 200px;
    margin-bottom: 5em;
}

#sectionPic{
    width: 100%;
    max-width: 550px;
    margin: auto;
}

.sectionPic img{
    width: 100%;
}

p{
    color: var(--lightgrey);
}

.darkPara{
    color: var(--darkText);
    font-weight: 500;
}

.btn{
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: transparent;
    color: var(--white);
    font-size: 1.1rem;
    padding: .7em 1.5em;
    cursor: pointer;
}

.btn1{
    margin-right: 1em;
}

.btn2{
    border: none;
}

.primaryBtn{
    background-color: var(--primarycolor);
}

@keyframes bounce {
    0% {transform:translateY(-30px);}
    50% {transform:translateY(30px);}
    100% { transform:translateY(-30px)}
  }

.bouncepic img{
   animation: bounce 15s infinite;    
}

.sectionHeader{
    color: var(--white);
    font-size: 2rem;
    margin-bottom: .7em;
}

.sectionPara{
    font-size: 1.1rem;
    margin-bottom: 3em;
}

@media screen and (min-width:800px) {
    section{
        width: 85%;
    }   
    
    .gridSection{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width:1000px){
    .sectionHeader{
        font-size: 3em;
    }
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

:root{
   --black:#000;
   --white:#ffffff;
   --lightgrey:#f1f1f1;
   --darkblue:#0d102c;
   --primarycolor:#3d51f2;
   --secondarycolor:#5670ef;
   --lightDark:#080A25;
   --darkText:#8388B4;
}

body{
    font-family: 'Poppins', sans-serif;
    background-color: var(--darkblue);
    color: var(--white);
}

/* Header */
header{
    width: 90%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 1em;
}

#barContainer{
    color: var(--white);
    font-size: 1.5rem;
    cursor: pointer;
}

.nav{
    list-style-type: none;
    color: var(--white);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: var(--lightDark);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
    z-index: 2;
}

.showNav{
    padding: 1em;
    padding-top: 5em;
    width: 70%;
    max-width: 300px;

}

.navLogo{
   margin-bottom: 3rem;
}

.nav-link{
    font-size: 1rem;
    width: 100%;
    font-weight: 500;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
}

.nav-link a{
    color: var(--white);
}

.sociallinkContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
}

.sociallink{
    margin-right: 1em;
    cursor: pointer;
}

@media screen and (min-width:800px) {
    .nav{
        position: relative;
        width: auto;
        background-color: transparent;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 5%;
    }
    .nav-link{
          margin-right: 2em;
    }
    .navLogo,#barContainer,.sociallinkContainer{
        display: none;
    }
}

/* Hero page */
.headline{
    color: var(--white);
    font-size: 3rem;
    width: 100%;
}

.cryptoText{
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #fff;
}

.btnContainer{
    margin-top: 2em;
}

.fa-play{
    margin-left: .5em;
}

.sectionPic{
    order: 0;
}

.sectionDesc{
    order: 1;
    margin-top: 1em;
}

@media screen and (min-width:800px) {
    .sectionPic{
        order: 1;
    }
    
    .sectionDesc{
        order: 0;
    }  
}
@media screen and (min-width:1000px){
    .headline{
        font-size: 4em;
    }
}

/* carousel */
.carouselContainer{
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    overflow: hidden;
    border-radius: 20px;
    scroll-behavior: smooth;
}


.eachCarousel{
    background-color: var(--lightgrey);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1em;
    border-radius: 20px;
    margin: auto;
    width: 100%;
    height: 300px;
}
.carouselDesc{
    margin-left: 1em;
}

.carouselTitle{
    color: var(--darkblue);
    font-size: 1.5rem;
    margin-bottom: 1em;
}

.carouselPara{
    color: var(--lightDark);
    font-weight: 500;
    margin-bottom: 1em;
}

.carouselPrice{
    color: var(--darkblue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin-bottom: 1em;
}

.carouselDiscount{
    color: var(--primarycolor);
}

.rect{
    width: 10px;
    height: 10px;
    background-color: var(--lightDark);
}

.carouselBtn{
    background-color: var(--secondarycolor);
    margin-top: 20px;
    text-transform: uppercase;
}

.carouselIndicator{
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.indicator{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--lightgrey);
    cursor: pointer;
    margin-right: 10px;
    opacity: .5;
}

.activeIndicator{
   opacity: 1;
}

@media screen and (min-width:700px) {
    .carouselContainer{
        grid-auto-columns: calc(100%/2);
    }

    .eachCarouselBorder{
        border: 5px solid var(--primarycolor);
    }
    
}

@media screen and (min-width:1100px) {
    .carouselContainer{
        grid-auto-columns: calc(100%/3);
    }

}

/* Processes */
.processessDesc{
    order: 0;
}

.processesPic{
  order: 1;
}

.eachProcesses{
    display: flex;
    margin-bottom: 2em;
}

.eachProcesses img{
    margin-right: 2em;
}

.processTitle{
    color: var(--white);
    margin-bottom: 1em;
}

/* Markets */

.marketDesc{
    order: 0;
}

.eachMarket{
    display: flex;
    margin-bottom: 2em;
}

.eachMarket img{
    margin-right: 2em;
}

.marketTitle{
    color: var(--white);
    margin-bottom: 1em;
}

.marketspicSection{
    background-color: var(--lightgrey);
    border-radius: 20px;
    padding: 1em;
}

.marketspicHeader{
    color: var(--darkblue);
    text-align: center;
    padding: 1em;
    text-transform: uppercase;
}

.marketsPicContainer{
    width: 100%;
    max-width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 1em;
    margin: auto;
}

.marketPic{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    border-radius: 20px;
}

.marketPic1{
    background-color: #FC9531;
}

.marketPic2{
  background-color: #4990FF;
}

.marketPic3{
  background-color: #3EC2F1;
}

.marketPic4{
   background-color: #50D79F;
}

.marketPic img{
    width: 50px;
}

.marketTitle{
  color: var(--white);
  font-weight: bold;
  margin-top: .5em;
  text-transform: uppercase;
}

/* Dashboard */
.dashboardDesc{
    order: 0;
}

.dashboardPic{
    order: 1;
    margin-top: 2em;
}

@media screen and (min-width:800px) {
    .dashboardDesc{
        order: 1;
    }
    
    .dashboardPic{
        order: 0;
    }
}


/* Funds */
.fundSection{
    width: 90%;
    max-width: 1100px;
    margin: auto;
    margin-top: 5em;
    margin-bottom: 5em;


}
.fundsContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.fund{
    margin-top: 3em;
}

.fundType{
    color: var(--white);
    margin-bottom: 1em;
    font-size: 1.5rem;
}

@media screen and (min-width:800px) {
    .fundSection{
        width: 85%;
    }    
}

@media screen and (min-width:1000px) {
    .fundsContainer{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .fund{
        margin-top: 1em;
    }
}

/* Newsletter */

.newsletter{
    border: 1px solid #fff;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0;
    overflow: hidden;
}

.newsletter input{
    font-size: 1.1rem;
    padding: .7em 1.5em;
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
}

.newsletter input::placeholder{
    color: var(--lightgrey);
}

.newsletter button{
    background-color: var(--primarycolor);
    font-weight: 700;
}

.newsletterDesc{
    order: 0;
}

.newsletterPic{
    order: 1;
}

@media screen and (min-width:800px) {
    .newsletterDesc{
        order: 1;
    }
    
    .newsletterPic{
        order: 0;
    }
}

/* Join Now */
footer{
    background-color: var(--lightDark);
    margin-top: 5em;
}

.joinSection,.footerlinksContainer{
    width: 90%;
    max-width: 1100px;
    margin: auto;
    padding: 1em;
}

.joinSection{
    display: flex;
    align-items: center;
    padding: 3em 0em;
}

.joinSection .joinDesc{
    width: 80%;
}

.joinSection button{
    height: auto;
}



.footerlinksContainer{
    width: 90%;
    max-width: 1100px;
    margin: auto;
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    justify-content: space-between;
    padding-top: 5em;
    border-top: 1px solid var(--lightgrey);
}

.footersociallinkContainer{
    margin-top: 1em;
}

.footerAboutus .sociallinkContainer{
    justify-content: flex-start;
}

.footerlink{
    display: flex;
    flex-direction: column;
    color: var(--white);
}

.linkTitle{
    font-size: 1.5rem;
    margin-bottom: .5em;
}

.footerlink a{
    color: var(--white);
    margin-bottom: 1em;
    transition: all .5s ease-in-out;
}

.footerlink a:hover{
    transform: translateX(10px);
}

.footerCopyright{
    background-color: var(--darkblue);
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
}

.footerCopyright p{
    text-align: center;
}

.developedBy{
    font-weight: bold;
    color: var(--white);
}

@media screen and (min-width:700px) {
    .footerlinksContainer{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}


/* Contact page */
.contactSection{
    margin-top: 8em;
}
.contactformContainer{
    display: flex;
    flex-direction: column;
    order: 1;
}

.contactPic{
    order: 0;
    width: 150px;
    background-color: rgba(241, 241, 241,.05);
    border-top-left-radius: 20%;
    
}

.contactPic img{
    width: 100%;
}

.contactForm div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.contactInput{
    width: 100%;
    font-size: 1.1rem;
    padding: 1em;
    margin-bottom: 1em;
    border-radius: 20px;
    border: none;
    outline: none;
    border: 2px solid var(--white);
    background-color: transparent;
    resize: none;
    color: var(--white);
}

.contactInput::placeholder{
    color: var(--lightgrey);
    text-transform: capitalize;
}

.contactBtn{
    width: 200px;
}

.addressHeader{
    text-align: center;
    color: var(--white);
    margin-bottom: 2em;
}

.address{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: space-between;
  width: 90%;
  max-width: 1100px;
  margin: auto;
}

.eachAddress h1{
    color: var(--white);
    margin-bottom: .5em;
    font-size: 1.5rem;
}

.eachAddress p{
    margin-bottom: 1em;
}

@media screen and (min-width:800px) {
    .contactformContainer{
        order: 0;
    }
    
    .contactPic{
        order: 1;
    }

    .address{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

/* About Us */
.aboutusDesc{
    order: 0;
}

.statusContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    width: 90%;
    max-width: 1100px;
    margin: auto;
}

.status h1{
    color: var(--white);
    font-size: 1.5rem;
    font-weight: lighter;
}

.status h1 b{
    font-weight: bold;
    font-size: 2rem;
}

/* offer */
.services{
    width: 90%;
    max-width: 1100px;
    margin: auto;
    margin-top: 4em;
}

.aboutusimgContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.aboutusimgContainer img{
    width: 100%;
    border-radius: 10px;
}


.offers{
    margin-top: 5em;
    display: grid;
    gap: 50px;
}

.eachOffer{
   display: flex;
   align-items: flex-start;
}

.eachOffer img{
    margin-right: 1em;
}

.offerDesc h1{
    margin-bottom: .5em;
    font-size: 1.5rem;
}

/* video */

.videoSection{
  display: grid;
}

.videoSectionHeader{
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: .5em;
    font-weight: bold;
}

.video{
    position: relative;
    margin-bottom: 1em;
    border-radius: 20px;
    overflow: hidden;
}

.thumbnail{
    width: 100%;
}

.playIcon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
}

/* Team */

.teamSection{
    margin-top: 5em;
}

.eachTeam{
    background-color: transparent;
    padding: 0;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.teamPic{
    position: relative;
    width: 80%;
    margin: auto;
}

.eachTeam img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.teamSocialLink{
    position: absolute;
    left: 50%;
    bottom: -25%;
    transform: translate(-50%);
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease-in-out;
}

.teamSocialLink img{
    width: 20px;
    margin-left: 1em;
}

.teamName{
    white-space: collapse;
}

.eachTeam:hover .teamSocialLink{
    bottom: 10%;
}


@media screen and (min-width:800px) {
    .aboutusDesc{
        order: 1;
    }   

    .statusContainer{
        gap: 50px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .offers{
        grid-template-columns: 1fr 1fr;
       gap: 20px;
    }

    .videoSection{
        grid-template-columns: 1fr 3fr;
      }

    .videoSectionHeader{
        z-index: 1;
        transform: translateX(50%);
    }
      
}

@media screen and (min-width:1000px) {
    .offers{
        grid-template-columns: 1fr 1fr 1fr;
    }

    .videoSectionHeader{
        font-size: 3.5rem;
    }
}