/* Pretty RULA CSS */

.eula {
  max-width: 800px;
  padding: 20px;
}

ol {
  margin: 0;
  padding: 0;
}
.eula table td,
table th {
  padding: 0;
}
.eula .c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.eula .c4 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 42pt;
  font-family: "Arial";
  font-style: normal;
}
.eula .c1 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.eula .c2 {
  padding-top: 24pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.eula .c3 {
  /* background-color: #ffffff; */
  max-width: 468pt;
  /* padding: 72pt 72pt 72pt 72pt; */
}
.eula .title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.eula .subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.eula li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
.eula p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
.eula h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.eula h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.eula h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.eula h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.eula h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.eula h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
